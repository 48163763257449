import walletLinear from '@/assets/images/wallet-linear.webp'
import splashWelcome from '@/assets/images/img_splash_comming.webp'
import splashJoinNow from '@/assets/images/img_splash_join_now.webp'
import commissionPending from '@/assets/images/pendding_commission_banner.webp'

export const imgsWallet = {
  walletLinear: walletLinear
}
export const imgsPopupIntro = {
  welcome: splashWelcome,
  joinNow: splashJoinNow,
  comminssionPending: commissionPending
}
